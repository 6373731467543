.sidebar_body_section{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sidebar_header{
    padding: 15px 5px 5px 5px;
}
.looped_navigation{
    margin-top: 30px;
}
.sidebar_footer{
    margin-top: 130px;
    cursor: pointer;
}
.sidebar_footer_route{
    padding: 10px 20px;
    /* margin-top: 20px; */
}
.webphone{
     filter: saturate(3); 
}
.tooltip{
    font-size: "13px";
    color: "white";
    font-family: "Work Sans";
    font-weight: 400;
    padding: '6px 16px';
    border-radius: "0px 10px 0px 0px";
    background-color: "#4d72f8";
    top: 6;
    position: 'relative';
    left: -10;
    line-height: 'auto';
    max-width: 'auto'
}
.modal-header .btnGrp{
    position: absolute;
    top: 8px;
    right: 10px;
  } 


  .min{
      width: 250px; 
      height: 35px;
      overflow: hidden !important;
      padding: 0px !important;
      margin: 0px;    

      float: left;  
      position: static !important; 
    }

  .min .modal-dialog, .min .modal-content{
      height: 100%;
      width: 100%;
      margin: 0px !important;
      padding: 0px !important; 
    }

  .min .modal-header{
      height: 100%;
      width: 100%;
      margin: 0px !important;
      padding: 3px 5px !important; 
    }

  .display-none{display: none;}

  button .fa{
      font-size: 16px;
      margin-left: 10px;
    }

  .min .fa{
      font-size: 14px; 
    }

  .min .menuTab{display: none;}

  button:focus { outline: none; }

  .minmaxCon{
    height: 35px;
    bottom: 1px;
    left: 1px;
    position: fixed;
    right: 1px;
    z-index: 9999;
  }

