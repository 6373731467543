*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  background: #E5E5E5;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 3px;
  height: 30px;
}

/* ::-webkit-scrollbar-track {
  background-color: #f1f1f1;

} */

::-webkit-scrollbar-thumb {
  background-color: #757575;
  height: 120px;
}

/* .MuiInputBase-root :focus {
  border: none;
} */

/* .MuiTypography-body1{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
} */
.k-dialog-titlebar {
  background-color: black !important;
}

.MuiTypography-body1 {
  font-family: 'Work Sans' !important;
  letter-spacing: 0.5px !important
}

.MuiTableCell-root {
  font-family: 'Work Sans' !important;
}

.MuiChip-label {
  padding-left: 0px !important;
}

.MuiOutlinedInput-inputAdornedEnd {
  font-family: 'Work Sans' !important;
}

.MuiInputBase-input {
  font-family: 'Work Sans' !important;
}

.popup-content {
  z-index: 15000 !important;
  position: fixed !important;
}



#popup-1 {
  z-index: 15000;
}

/* .k-window{
  top: 190.5px;
  left: 356.5px;
  /* width: 405px!important; */
/* height: 600px !important;
  z-index: 10002;
  /* background-color: black !important; */
/* background: "black";
} */
.k-widget .k-window {
  z-index: 15000 !important;
}

.k-window {
  width: 300px !important;
  height: 580px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  z-index: 15000 !important;
}


.k-window-content {
  padding: 0px !important;
  overflow: none !important;
  position: sticky !important;
}



.MuiTab-root {
  min-width: 125px !important;
}

/* .MuiPaper-elevation1 {
  border-radius: 0px !important;
} */

.k-i-close {
  display: none !important;
  background-color: black !important;
  border: none !important;
}

.k-icon-button.k-button-md {
  padding: 0 !important;
}

#ringing {
  animation: MoveUpDown 1s linear infinite;

}

@keyframes MoveUpDown {

  0%,
  100% {
    bottom: 0;
  }

  50% {
    bottom: 20px;
  }
}

/* .MuiTypography-displayBlock {
  font-family: 'Work Sans',
} */