.sidebar_element_section{
    display: flex;
    padding: 10px 20px;
    margin: 15px 0;
    cursor: pointer;
}


.sidebar_element_section_active{
    padding: 8px 20px 9px;
    margin: 15px 0;
    cursor: pointer;
    background-color: #4d72f8;
    border-radius: 0px 20px 20px 0px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
    position:relative;
    left: -3px;
    top: 0px !important;
    line-height: 12px;
}
.sidebar_element_section_active1{
    padding: 8px 20px 9px;
    margin: 15px 0;
    cursor: pointer;
    background-color: red;
    border-radius: 0px 20px 20px 0px;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 20%);
    position:relative;
    left: -3px;
    top: 0px !important;
    line-height: 12px;
}

.sidebar_element_section_active svg{color:#fff !important}
a:-webkit-any-link:active {
    background-color: white;
    color: '#DFDFDF',
}
