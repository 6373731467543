
.layout_section{
    display: flex;
    position:relative;
}
.sidebar_container{
    margin: 0;
    display: flex;
    height: 100vh;
    position: sticky;
    top: 0;
    z-index: 1;
}
@media screen and (max-width: 768px) {
  .sidebar_container {
        display: none;
  }
}
/* @media screen and (max-width: 992px) {
  .sidebar_container {
        display: none;
  }
} */
.main_body_section{
    padding: 20px;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
    flex-grow: 1
}
.sidebar_wrapper{
    background-color: #F3F5FF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-right: 1px solid rgba(185, 184, 184, 0.2);
    height: 100%;
}
.sidebar_child_component{
    /* margin-top: 56px; */
    z-index: 0;
}
.childComponenets{
    background-color: inherit;
    /* margin-top: 70px; */
    margin-top: 90px;
    margin-left: 307px;
    @media (max-width: 768px):{
        margin-left: 0px;
    }
}
.header_section{
    margin-left: 100px;
    
}
.footer_container { 
    position: fixed; 
    background-color: #1274AC; 
    /* width: 40px; */ 
    bottom: 40px; 
    right: 80px; 
    color: white; 
    padding: 8px; 
    width:290px
}