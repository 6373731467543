.iconSizeanswer {
    -webkit-animation: upNdown 2s infinite linear;
    animation: upNdown 2s infinite linear;
}
@-webkit-keyframes upNdown {
     0% { }
     50% { transform: translate(-5px); }
     100% { }
}
@keyframes upNdown {
     0% { }
     50% { transform: translate(-5px); }
     100% { }
}